@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');



body{
margin: 0;
padding: 0;
overflow-y: auto;
box-sizing: border-box;
min-height: 100vh;
overflow-x: hidden;
background-color:"#081028";
 

}

