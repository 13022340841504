@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.artist-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0B1739;
  padding: 20px;
  border-radius: 10px;
  max-width: 999px;
  height: 592px;
}

.artist-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.artist-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-button {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #A9A9A9;
}

.photo-preview {
  width: 100px;
  height: 100px;
  margin-top: 10px;
  border-radius: 50%;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

form div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

form label {
  font-weight: bold;
}

form input[type="text"],
form input[type="number"],
form textarea,
form input[type="file"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

form button {
  padding: 10px;
  background-color: #343B4F;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

form button:hover {
  background-color: #2b3245;
}

.photo-preview {
  width: 100px;
  height: 100px;
  margin-top: 10px;
  border-radius: 50%;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

form div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

form label {
  font-weight: bold;
}

form input[type="text"],
form input[type="number"],
form textarea,
form input[type="file"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

form button {
  padding: 10px;
  background-color: #343B4F;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

form button:hover {
  background-color: #2b3245;
}

.Artistright {
  width: 440.5px;
  height: 445px;
  position: relative;
  top: 60px;
  display: flex;
  flex-direction: column;
  margin-right: 60px;
}

.Artist-head {
  font-size: 24px;
  color: white;
  display: flex;
  margin-right: 70%;
  position: relative;
  margin-top: -30px;
  line-height: 32px;
  left: 0px;
  height: 32px;
  font-family: Poppins;
  width: 200px;
  white-space: nowrap;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #A9A9A9;
  font-family: Poppins;
  font-weight: 400;
  line-height: 14px;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  font-family: Poppins;
}

@media (max-width: 1024px) {
  .artist-modal {
    width: 95%;
    height: auto;
  }

  .artist-modal-content {
    padding: 15px;
  }

  .Artist-head {
    margin-right: 0;
  }

  .Artistright {
    margin-right: 0;
    width: 100%;
  }

  .description {
    flex-direction: column;
    align-items: flex-start;
  }

  .description-textarea {
    width: 100%;
    margin-left: 0;
  }

  .form-group {
    width: 100%;
  }

  .custom-file-upload {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  .custom-file-upload img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .button-modal {
    width: 100%;
    margin-top: 20px;
    left: 0;
    right: 0;
    transform: translateX(-50%);
  }
}

@media (max-width: 640px) {
  .artist-modal-content {
    padding: 10px;
  }

  .Artist-head {
    font-size: 20px;
    margin-top: 0;
  }

  .Artistright {
    width: 100%;
    margin-right: 0;
  }

  .Artist-name {
    width: 100%;
    margin-bottom: 15px;
  }

  .Artist-name input {
    margin-left: 0;
    width: 100%;
  }

  .description {
    flex-direction: column;
  }

  .description-textarea {
    width: 100%;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .form-group {
    width: 100%;
  }

  .custom-file-upload {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  .custom-file-upload img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .button-modal {
    width: 100%;
    margin-top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .gap-between-elements {
    gap: 10px;
  }

  @media (max-width: 640px) {
  .artist-modal-content {
    padding: 10px;
  }

  .Artist-head {
    font-size: 20px;
    margin-top: 0;
  }

  .Artistright {
    width: 100%;
    margin-right: 0;
  }

  .Artist-name {
    width: 100%;
    margin-bottom: 15px;
  }

  .Artist-name input {
    margin-left: 0;
    width: 100%;
  }

  .description {
    flex-direction: column;
  }

  .description-textarea {
    width: 100%;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .form-group {
    width: 100%;
  }

  .custom-file-upload {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  .custom-file-upload img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .button-modal {
    width: 100%;
    margin-top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .gap-between-elements {
    gap: 10px;
  }

}
}