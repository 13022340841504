@import 'normalize.css';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* index.css */
body {
  @apply bg-customBlue;
}


